import { routes } from "pages/home";
import { Navigate, Route, Routes } from "react-router-dom";
import { Nav } from "./../pages/home/nav";
import MyCard from "./mycard";
import Profile from "./../pages/businessCard/index";
import { MyInfo } from "pages/info";
import { Account } from "pages/account";
import { TabNav } from "./../components/tabNav";
import { useContext, useEffect, useState } from "react";
import { Intro } from "./intro";
import { UserContext } from "store/user/userContext";
import { Spinner } from "./loader";
import { motion, AnimatePresence } from "framer-motion";
import {
  CreditCardIcon,
  DocumentTextIcon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useRouteNav } from "./routeNavigation";

const AppRoutes = () => {
  const [showIntro, setShowIntro] = useState<string | null>();
  const { profile, loadingProfile, userIsNew, userChecked } =
    useContext(UserContext);

  useEffect(() => {
    if (userIsNew) {
      setShowIntro("yes");
    }
  }, [userIsNew]);

  const goToInfo = () => {
    setShowIntro("");
  };

  if (loadingProfile) {
    return (
      <div className="flex flex-col justify-center items-center mt-8">
        <Spinner />
      </div>
    );
  }

  if (profile) {
    return <ProfileRoutes />;
  }

  if (userIsNew && showIntro === "yes") {
    return (
      <div className="">
        <div className="p-4">
          <Nav />
        </div>
        <Intro onGo={goToInfo} />
      </div>
    );
  }

  if (userIsNew && showIntro === "") {
    return (
      <div>
        <div className="p-4">
          <Nav />
        </div>
        <MyInfo />
      </div>
    );
  }

  return <></>;
};

const ProfileRoutes = () => {
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false);
  const { currentRoute } = useRouteNav();
  const toggleLeftPanel = () => {
    setIsLeftPanelOpen(!isLeftPanelOpen);
  };
  const menuItems = [
    {
      icon: CreditCardIcon,
      label: "Privacy",
      url: "https://pass-card.app/privacy",
    },
    {
      icon: DocumentTextIcon,
      label: "Terms",
      url: "https://pass-card.app/terms",
    },
  ];

  return (
    <>
      <div className="flex h-screen overflow-x-hidden">
        <AnimatePresence initial={false}>
          {isLeftPanelOpen && (
            <motion.div
              key="left-panel"
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ x: "-100%" }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className="bg-slate-50 h-full w-full overflow-x-hidden fixed left-0 top-0 z-10 flex flex-col pt-8 pr-4 pl-4 pb-8"
            >
              <MyInfo />
            </motion.div>
          )}
        </AnimatePresence>

        <motion.div
          animate={{ marginLeft: isLeftPanelOpen ? "216px" : 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          className="flex-1 bg-white p-1  w-screen min-w-full"
        >
          {currentRoute === routes.qr && (
            <motion.button
              onClick={toggleLeftPanel}
              className="fixed top-4 right-0 -translate-y-1/2 bg-white border-gray-300  rounded-full text-primary p-2 border m-2 shadow-2xl duration-200 z-20"
              transition={{ duration: 0.3, ease: "easeInOut" }}
            >
              {isLeftPanelOpen ? (
                <XMarkIcon className="h-5 w-5" />
              ) : (
                <PencilSquareIcon className="h-5 w-5" />
              )}
            </motion.button>
          )}
          <div className="relative">
            <Routes>
              <Route path={routes.qr} element={<MyCard />} />
              <Route path={routes.card} element={<Profile />} />
              <Route path={routes.account} element={<Account />} />
              <Route
                path="/"
                element={<Navigate to={routes.qr} replace={true} />}
              />
            </Routes>
            <TabNav />
          </div>
        </motion.div>
      </div>
    </>
  );
};

export { AppRoutes };
