import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "store/user/userProvider";
import SnackbarProvider from "react-simple-snackbar";
import { useOnlineStatus } from "services/useOnlineStatus";
import { OfflineView } from "./../components/offline";
import { AppRoutes } from "./../components/approutes";
export const routes = {
  account: "/account",
  sub: "/account/subscription",
  myId: "/myid",
  card: "/card",
  qr: "/qr",
  tickets: "/tickets",
  myInfo: "/info",
  settings: "/settings",
};
const Home = () => {
  const store = useOnlineStatus();
  if (!store) {
    return <OfflineView />;
  }

  return (
    <div className="h-screen w-screen">
      <UserProvider>
        <SnackbarProvider>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </SnackbarProvider>
      </UserProvider>
    </div>
  );
};

export { Home };
