import { useEffect, useState } from "react";
import {
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  AuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { authModule } from "../config";

const useAuth = () => {
  const providers = {
    google: new GoogleAuthProvider(),
    microsoft: new OAuthProvider("microsoft.com"),
    apple: new OAuthProvider("apple.com"),
  };
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [user, setUser] = useState(authModule.currentUser);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unlisten = onAuthStateChanged(authModule, async (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoadingUser(false);
    });
    return () => {
      unlisten();
    };
  }, []);

  const loginWithGoogle = () => {
    login(providers.google);
  };

  const loginWithMicrosoft = async () => {
    login(providers.microsoft);
  };

  const loginWithApple = async () => {
    login(providers.apple);
  };

  const login = async (provider: AuthProvider) => {
    setLoading(true);
    await signInWithPopup(authModule, provider).catch((err) => {
      setError(err);
      setLoading(false);
    });
    setLoading(false);
  };

  const logout = () => {
    signOut(authModule);
  };

  return {
    loadingUser,
    loading,
    user,
    error,
    loginWithGoogle,
    loginWithMicrosoft,
    loginWithApple,
    logout,
  };
};

export { useAuth };
