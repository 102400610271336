import { SpinnerDotted } from "spinners-react";
import { useContext, useState } from "react";
import { UserContext } from "store/user/userContext";
import { useAuth } from "services/authServer";
import { CardLinks } from "./cardlinks";
import { Header } from "./header";
import { CardCode } from "./cardCode";
import { Sheet } from "react-modal-sheet";
import { MyInfo } from "pages/info";

function MyCard() {
  const { loading, loadingUser, user } = useAuth();
  const [showForms, setShowForms] = useState(false);
  const { profile, cardInfo, loadingProfile } = useContext(UserContext);

  if (loading || loadingProfile || loadingUser) {
    return (
      <div className="flex w-screen h-screen">
        <div className="m-auto">
          <SpinnerDotted
            size={50}
            thickness={100}
            speed={100}
            color="#36ad47"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center flex-col items-center">
      {profile && (
        <div className="bg-white border rounded-2xl m-2 shadow-2xl max-w-sm w-full">
          <div className="p-1 grid">
            <div className="max-w-sm rounded-md min-w-full">
              <div className="grid divide-slate-200 divide-y gap-">
                <Header
                  img={profile.profile}
                  names={profile.general?.full_names}
                  job_title={profile.general?.job_title}
                  company={profile.general?.company}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <CardLinks
              email={profile.general?.email}
              phone={profile.general?.phone}
              website={profile.general?.website}
              links={profile.links}
            />
          </div>
        </div>
      )}
      <div className="fixed bottom-20 flex justify-center max-w-sm w-full">
        {profile && <CardCode cardInfo={cardInfo} />}
      </div>
      <Sheet isOpen={showForms} onClose={() => setShowForms(false)}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content className="flex flex-col items-center">
            <MyInfo />
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
      </Sheet>
    </div>
  );
}

export default MyCard;
