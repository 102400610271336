const Header = ({
  img,
  names,
  job_title,
  company,
}: {
  img?: string | null;
  names?: string | null;
  job_title?: string | null;
  company?: string | null;
}) => {
  return (
    <div className="mt-4">
      <div className="flex min-h-full items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="space-y-">
          {img && (
            <img
              className="mx-auto h-24 w-24 shadow-md rounded-full bg-slate-100 object-cover"
              src={img}
              alt={`${names} profile`}
            />
          )}
          <h4 className="mt-2 text-center text-xl font-bold tracking-tight text-gray-700">
            {names}
          </h4>
          <p className="text-center text-md font-medium tracking-tight text-gray-500">
            {job_title && <span>{job_title}</span>}{" "}
            <span>{company && <>@ {company}</>}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export { Header };
