import { useState } from "react";
import { motion } from "framer-motion";
import {
  BriefcaseIcon,
  IdentificationIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { routes } from "pages/home";
import { useRouteNav } from "./routeNavigation";

function TabNav() {
  const tabs = [
    { id: routes.qr, label: "Business Card", icon: IdentificationIcon },
    { id: routes.card, label: "Profile", icon: BriefcaseIcon },
    { id: routes.account, label: "Account", icon: UserIcon },
  ];

  const { goTo } = useRouteNav();
  const [activeTab, setActiveTab] = useState(routes.qr);

  return (
    <div className="flex justify-center items-center fixed bottom-4 right-0 left-0">
      <nav className="bg-white p-2 rounded-full shadow-lg">
        <ul className="flex space-x-1">
          {tabs.map((tab) => (
            <li key={tab.id}>
              <button
                onClick={() => {
                  setActiveTab(tab.id);
                  goTo(tab.id);
                }}
                className={`relative flex items-center space-x-2 px-4 py-2 rounded-full transition-all duration-300${
                  activeTab === tab.id
                    ? "text-accent bg-background"
                    : "text-gray-500 hover:bg-gray-100"
                }`}
              >
                <tab.icon
                  className={`w-6 h-6 ${
                    activeTab === tab.id ? "text-secondary" : ""
                  }`}
                />
                <motion.span
                  initial={{ width: 0, opacity: 0 }}
                  animate={{
                    width: activeTab === tab.id ? "auto" : 0,
                    opacity: activeTab === tab.id ? 1 : 0,
                  }}
                  transition={{ duration: 0.3 }}
                  className={`overflow-hidden whitespace-nowrap ${
                    activeTab === tab.id ? "text-secondary" : ""
                  }`}
                >
                  {tab.label}
                </motion.span>
                {activeTab === tab.id && (
                  <motion.div
                    className="absolute bottom-0 left-0 right-0 h-2 w-2 bg-accent rounded-full"
                    layoutId="activeTabIndicator"
                  />
                )}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

export { TabNav };
