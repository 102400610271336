import { IUserInfoForms } from "pages/info/useInfo";
import { createContext } from "react";
import { Sub } from "./userProvider";

export interface TeamSub {
  expiry: string;
}

export interface Brand {
  bio: string;
  logo: string;
  tag?: string;
  wall?: string;
  name: string;
  website: string;
}
export interface Team {
  brand: Brand;
  sub: TeamSub;
  theme: Theme;
}

export interface Theme {
  primary: string;
  secondary: string;
  background: string;
  accent: string;
}

export interface UserContextData {
  profile: IUserInfoForms | null;
  cardInfo?: string | null;
  loadingProfile: boolean;
  expiry?: Date | null;
  isExpired: boolean;
  sub?: Sub;
  loadingSub: boolean;
  profileError: string | null;
  userIsNew: boolean;
  userChecked: boolean;
  team?: any | null;
  theme: Theme;
  getProfile: () => void;
  setSub: (payload: any) => void;
}
export const UserContextDefaultValues: UserContextData = {
  profile: null,
  loadingProfile: false,
  cardInfo: "",
  profileError: null,
  expiry: new Date(),
  isExpired: false,
  loadingSub: false,
  userIsNew: false,
  userChecked: false,
  theme: { primary: "", secondary: "", background: "", accent: "" },
  getProfile(): void {},
  setSub(payload) {},
};

export const UserContext = createContext<UserContextData>(
  UserContextDefaultValues
);
