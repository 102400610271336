import { useState } from "react";
import QrCodeView from "./qrCodeView";
function OfflineView() {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    { title: "Contacts", content: localStorage.getItem("offlineCard") },
    { title: "Profile", content: localStorage.getItem("offlineLink") },
  ];
  const offlineCard = localStorage.getItem("offlineCard");
  const offlineLink = localStorage.getItem("offlineLink");

  if (!offlineCard || !offlineLink) {
    return (
      <div
        className="flex items-center p-4 mb-4 text-sm text-emerald-700 rounded-lg bg-emerald-50 dark:bg-emerald-800 dark:text-emerald-400 m-2"
        role="alert"
      >
        <svg
          className="flex-shrink-0 inline w-4 h-4 mr-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span className="sr-only">Info</span>
        <div>
          <span className="font-medium">You are offline!</span> You are viewing
          an offline version of this app. Please connect to the internet to
          continue using the app.
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-8">
      <div
        className="flex items-center p-4 mb-4 text-sm text-emerald-700 rounded-lg bg-emerald-50 dark:bg-emerald-800 dark:text-emerald-400 m-2"
        role="alert"
      >
        <svg
          className="flex-shrink-0 inline w-4 h-4 mr-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span className="sr-only">Info</span>
        <div>
          <span className="font-medium">Offline mode!</span> You are viewing an
          offline version of this app. Please connect to the internet to get the
          full version.
        </div>
      </div>

      <div className="w-full max-w-2xl mx-auto p-4">
        <div className="flex space-x-1 rounded-xl bg-slate-100 p-1">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`flex-1 text-center py-2 px-4 text-sm font-medium rounded-lg transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 ${
                activeTab === index
                  ? "bg-emerald-500 text-white shadow"
                  : "bg-transparent text-slate-700 hover:bg-slate-200"
              }`}
              onClick={() => setActiveTab(index)}
              aria-selected={activeTab === index}
              role="tab"
            >
              {tab.title}
            </button>
          ))}
        </div>
        <div className="mt-4 rounded-lg bg-white p-6 shadow-sm">
          <div className="flex justify-center items-center">
            <QrCodeView
              url={tabs[activeTab].content}
              caption="Scan code to connect with me"
              fullSize
            />
          </div>
        </div>
      </div>
      <footer className="fixed bottom-0 left-0 right-0 flex md:items-center md:justify-between mb-0">
        <div className="w-full">
          <div className="bg-white w-full p-4 flex justify-center">
            <span className="text-xs text-gray-500 sm:text-center dark:text-gray-400 ">
              Powered by{" "}
              <span className="font-semibold text-sm">
                <a
                  href="https://digital.pass-card.app"
                  className="hover:underline"
                >
                  Pass
                </a>
              </span>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}

export { OfflineView };
