import { useEffect, useState } from "react";
import {
  TrashIcon,
  PlusIcon,
  PencilIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import { TextInput } from "flowbite-react";
import { Spinner } from "./../../components/loader";

interface LinkManagerProps {
  loading: boolean;
  initialLinks?: string[];
  onCommit: (links: string[]) => void;
}

export default function LinkManager({
  loading,
  initialLinks,
  onCommit,
}: LinkManagerProps) {
  const [links, setLinks] = useState<string[]>([]);
  const [newLink, setNewLink] = useState("");
  const [editStates, setEditStates] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    setLinks(initialLinks || []);
  }, [initialLinks]);

  const addLink = (e: React.FormEvent) => {
    e.preventDefault();
    setLinks([...links, newLink]);
    setNewLink("");
  };

  const removeLink = (index: number) => {
    setLinks(links.filter((_, i) => i !== index));
    const newEditStates = { ...editStates };
    delete newEditStates[index];
    setEditStates(newEditStates);
  };

  const startEdit = (index: number) => {
    setEditStates({ ...editStates, [index]: links[index] });
  };

  const handleEdit = (index: number, value: string) => {
    setEditStates({ ...editStates, [index]: value });
  };

  const saveEdit = (index: number) => {
    const newLinks = [...links];
    newLinks[index] = editStates[index];
    setLinks(newLinks);
    const newEditStates = { ...editStates };
    delete newEditStates[index];
    setEditStates(newEditStates);
  };

  const saveAllChanges = () => {
    onCommit(links);
  };

  return (
    <div className="max-w-2xl mx-auto p-4 space-y-6">
      <p className="text-md font-semibold text-gray-900 dark:text-white">
        Add up to 8 links
      </p>
      <ul className="space-y-8">
        {links.map((link, index) => (
          <li key={index} className="flex items-center space-x-2">
            {editStates.hasOwnProperty(index) ? (
              <>
                <TextInput
                  type="url"
                  value={editStates[index]}
                  onChange={(e: any) => handleEdit(index, e.target.value)}
                  className="flex-grow"
                />
                <button
                  onClick={() => saveEdit(index)}
                  className="text-secondary text-md rounded-full p-2.5 bg-background"
                  aria-label="Save edit"
                >
                  <CheckIcon className="h-4 w-4" />
                </button>
              </>
            ) : (
              <>
                <span className="flex-grow truncate">{link}</span>
                <button
                  onClick={() => startEdit(index)}
                  className="text-secondary text-md rounded-full p-2.5 bg-background"
                  aria-label="Edit link"
                >
                  <PencilIcon className="h-4 w-4" />
                </button>
              </>
            )}
            <button
              onClick={() => removeLink(index)}
              className="text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              aria-label={`Remove ${link}`}
            >
              <TrashIcon className="h-4 w-4" />
            </button>
          </li>
        ))}
      </ul>

      {links.length < 8 && (
        <form
          onSubmit={addLink}
          className="flex space-x-2 justify-center items-center "
        >
          <TextInput
            type="url"
            value={newLink}
            onChange={(e: any) => setNewLink(e.target.value)}
            placeholder="Enter a new link"
            className="flex-grow "
            required
          />
          <button
            type="submit"
            className="text-secondary text-md rounded-full p-2.5 bg-background"
          >
            <PlusIcon className="h-4 w-4" />
          </button>
        </form>
      )}
      {links.length > 0 && (
        <button
          type="submit"
          className="text-secondary text-md rounded-md p-2 mt-4 bg-background w-full"
          disabled={loading}
          onClick={saveAllChanges}
        >
          {loading ? <Spinner /> : "Update links"}
        </button>
      )}
    </div>
  );
}
